import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FingerPrintView } from "../../../features/views/FingerPrintView/FingerPrintView";
import { Button } from "react-bootstrap";
import { RootState, allActions } from "../../../app/store";
import { ArrowIcon, PlayButton } from "../../../features/icons/Icons";
import { useHistory, useLocation } from "react-router-dom";
import {
  FingerprintForm,
  DropdownField,
  FingerprintTextInput,
  FingerprintTextArea,
  ValidationValuesCheckList,
} from "./FingerprintForm";
import {
  CountriesStates,
  Genders,
  Ages,
  Industries,
  Educations,
  Incomes,
} from "./DropdownOptions";
import { VIDEO_VALUEDRIVERS } from "../../../assets/media";
import { useErrorHandler, useFormData, useValueData } from "../../../app/hooks";
import { IError } from "../../../services/user.service";
import SessionService from "../../../services/session.service";

const rootPath = "/digital-fingerprint";

export function PlaceholderView(props: any) {
  return (
    <FingerPrintView background={props.bg ? props.bg : "navy"}>
      {props.children}
    </FingerPrintView>
  );
}

export function NotVeryMuch(props: any) {
  const selector = useSelector((state: RootState) => {
    return state.appRoute;
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      allActions.appActions.updateFormLocation(
        selector.appRoute,
        "not-very-much",
        selector.nextRoute
      )
    );
  }, []);

  return (
    <FingerPrintView
      background={props.bg ? props.bg : "navy"}
      className="not-very-much"
    >
      {props.children}
      <FingerprintForm
        name={"Not Very Much"}
        next={selector.appRoute}
        back={selector.nextRoute}
        backText={"Continue Anyway"}
        nextText={"Go Back"}
        message={"You did not write very much."}
        backOrder={"9"}
        leftClose={true}
        arrowDirection={"left"}
        enoughText={true}
      >
        <p>
          Please be thoughtful and complete with your answers and provide as
          much detail as possible.
        </p>
      </FingerprintForm>
    </FingerPrintView>
  );
}

interface CountryStates {
  country: any;
  states: any;
}

export function AboutYou() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      allActions.appActions.updateFormLocation(
        "before-we-begin",
        "about-you",
        "your-professional-life"
      )
    );
  }, []);
  const { formData, addToForm } = useFormData();

  const [stateOptions, setStateOptions] = useState(["disabled"]);

  let countries: any = [];

  const DetermineStates = (state: string) => {
    const countryStates = CountriesStates.filter(
      (cs: CountryStates) => cs.country === state
    );
    if (countryStates[0].states.length > 0) {
      setStateOptions(countryStates[0].states);
    } else {
      setStateOptions(["No states available."]);
    }
  };

  useEffect(() => {
    if (
      formData.data["Country"] !== undefined &&
      formData.data["Country"] !== null &&
      Object.keys(formData.data).length !== 0
    ) {
      DetermineStates(formData.data["Country"]);
    }
  }, [formData]);

  const handleCountryChange = (event: any) => {
    const selection = event.replace("#/", "").replace("-", " ");
    const countryStates = CountriesStates.filter(
      (cs: CountryStates) => cs.country === selection
    );

    if (countryStates[0].states.length > 0) {
      setStateOptions(countryStates[0].states);
    } else {
      setStateOptions(["No states available."]);
    }
    addToForm({ name: "Country", value: selection });
  };

  CountriesStates.forEach((country) => {
    countries.push(country.country);
  });

  return (
    <FingerPrintView background="darkblue">
      <FingerprintForm
        name="About You"
        message="Tell us a little about you…"
        next="your-professional-life"
      >
        <DropdownField
          name="Gender"
          options={Genders}
          editForm={addToForm}
          formData={formData}
        />
        <DropdownField
          name="Age"
          options={Ages}
          editForm={addToForm}
          formData={formData}
        />
        <DropdownField
          name="Country"
          options={countries}
          onChange={handleCountryChange}
          editForm={addToForm}
          formData={formData}
        />
        <DropdownField
          name="State"
          options={stateOptions}
          editForm={addToForm}
          formData={formData}
        />
      </FingerprintForm>
    </FingerPrintView>
  );
}

export function ProfessionalLife() {
  const dispatch = useDispatch();
  const { formData, addToForm } = useFormData();

  useEffect(() => {
    dispatch(
      allActions.appActions.updateFormLocation(
        "about-you",
        "your-professional-life",
        "a-few-questions"
      )
    );
  }, []);

  return (
    <FingerPrintView background="darkblue">
      <FingerprintForm
        name="Your Professional Life"
        message="And your professional life…"
        next="a-few-questions"
        back="about-you"
      >
        <DropdownField
          name="Industry"
          options={Industries}
          other={true}
          editForm={addToForm}
          formData={formData}
        />
        <FingerprintTextInput
          name="Role"
          placeholder="Role"
          editForm={addToForm}
          formData={formData}
        />
        <DropdownField
          name="Education"
          options={Educations}
          editForm={addToForm}
          formData={formData}
        />
        <DropdownField
          name="Income"
          options={Incomes}
          editForm={addToForm}
          formData={formData}
        />
      </FingerprintForm>
    </FingerPrintView>
  );
}

export function FirstAFewQuestions() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      allActions.appActions.updateFormLocation(
        "your-professional-life",
        "a-few-questions",
        "earliest-memory"
      )
    );
  }, []);

  return (
    <FingerPrintView background="navy">
      <FingerprintForm
        name="A Few Questions"
        message="We will first ask a few questions to help you reflect on your story."
        next="earliest-memory"
        back="your-professional-life"
      >
        <p>
          This interactive experience is designed to guide you through a series
          of questions to help you reflect on your unique story. Formative
          experiences throughout your life present key moments where your values
          are molded and put to the test. Each question you encounter is
          generated by advanced AI, simulating a personalized conversation with
          a coach.
        </p>
        <p>
          Spend a few moments reflecting on each prompt, and write whatever
          comes to mind without worrying about structure. Remember, everyone's
          journey is different, so the questions you receive will adapt based on
          your responses, ensuring a truly individualized reflection process.
          Let your answers flow naturally, and embrace this opportunity for
          self-discovery.
        </p>
      </FingerprintForm>
    </FingerPrintView>
  );
}

export function YourParents() {
  const { formData, addToForm } = useFormData();
  const [enoughText, setEnoughText] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      allActions.appActions.updateFormLocation(
        "earliest-memory",
        "your-parents",
        "meaningful-relationship"
      )
    );
  }, []);
  return (
    <FingerPrintView background="darkblue">
      <FingerprintForm
        name="Your Parents"
        message="What did your parents want most for you? What sacrifices did they make to give you that."
        next="meaningful-relationship"
        back="earliest-memory"
        enoughText={enoughText}
      >
        <FingerprintTextArea
          name="your parents"
          editForm={addToForm}
          setEnoughText={setEnoughText}
          formData={formData}
        />
      </FingerprintForm>
    </FingerPrintView>
  );
}

export function MeaningfulRelationship() {
  const { formData, addToForm } = useFormData();
  const [enoughText, setEnoughText] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      allActions.appActions.updateFormLocation(
        "your-parents",
        "meaningful-relationship",
        "best-boss"
      )
    );
  }, []);

  return (
    <FingerPrintView background="darkblue">
      <FingerprintForm
        name="Meaningful Relationship"
        message="Describe your most meaningful relationship. What was it about that person that attracted you to them?"
        next="best-boss"
        back="your-parents"
        enoughText={enoughText}
      >
        <FingerprintTextArea
          name="Meaningful Relationship"
          editForm={addToForm}
          setEnoughText={setEnoughText}
          formData={formData}
        />
      </FingerprintForm>
    </FingerPrintView>
  );
}

export function BestBoss() {
  const { formData, addToForm } = useFormData();
  const [enoughText, setEnoughText] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      allActions.appActions.updateFormLocation(
        "meaningful-relationship",
        "best-boss",
        "conflict-moment"
      )
    );
  }, []);

  return (
    <FingerPrintView background="darkblue">
      <FingerprintForm
        name="Best Boss"
        message="Describe the best boss you've ever had. What about them endeared them to you or inspired you?"
        next="conflict-moment"
        back="meaningful-relationship"
        enoughText={enoughText}
      >
        <FingerprintTextArea
          name="Best Boss"
          editForm={addToForm}
          setEnoughText={setEnoughText}
          formData={formData}
        />
      </FingerprintForm>
    </FingerPrintView>
  );
}

export function ConflictMoment() {
  const { formData, addToForm } = useFormData();
  const [enoughText, setEnoughText] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      allActions.appActions.updateFormLocation(
        "best-boss",
        "conflict-moment",
        "video-value-drivers"
      )
    );
  }, []);

  return (
    <FingerPrintView background="darkblue">
      <FingerprintForm
        name="Conflict Moment"
        message="Describe a great moment of conflict you've faced. How did you go about resolving it?"
        next="video-value-drivers"
        back="best-boss"
        enoughText={enoughText}
      >
        <FingerprintTextArea
          name="Conflict Moment"
          editForm={addToForm}
          setEnoughText={setEnoughText}
          formData={formData}
        />
      </FingerprintForm>
    </FingerPrintView>
  );
}

export function VideoValueDrivers() {
  const dispatch = useDispatch();

  const [autoPlaying, setAutoPlaying] = useState(false);

  useEffect(() => {
    dispatch(
      allActions.appActions.updateFormLocation(
        "conflict-moment",
        "video-value-drivers",
        "calculating-potential-values"
      )
    );
  }, []);

  function toggleAutoPlay() {
    setAutoPlaying(true);
  }

  return (
    <FingerPrintView background="navy">
      <FingerprintForm
        name="Value Drivers Intro"
        message="Thank you. Now let's examine your Value-Drivers."
        back="potential-values"
        next="add-values"
        nextText="Go"
        navMessage="Please watch the video before advancing."
      >
        <div className="video-wrapper">
          {autoPlaying && (
            <video autoPlay controls>
              <source src={VIDEO_VALUEDRIVERS.default} type="video/mp4" />
            </video>
          )}
          {!autoPlaying && (
            <video>
              <source src={VIDEO_VALUEDRIVERS.default} type="video/mp4" />
            </video>
          )}
          <div
            className={`play-button-wrapper ${autoPlaying ? "hide" : ""}`}
            onClick={toggleAutoPlay}
          >
            <PlayButton />
            <span>Play Video</span>
          </div>
        </div>
      </FingerprintForm>
    </FingerPrintView>
  );
}

export function ConfirmFingerprint() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      allActions.appActions.updateFormLocation(
        "value-order",
        "confirm-fingerprint",
        "craft-narrative"
      )
    );
  }, []);

  const { licenseCode, user } = SessionService.getInstance();

  return (
    <FingerPrintView background="navy" className="confirmation no-shapes">
      <FingerprintForm
        name="Confirm Fingerprint"
        message="Are You Sure?"
        back="value-order"
        next={`${licenseCode}`}
        nextText="I'm Sure"
        backText="Maybe not, take me back"
        backOrder="9"
        leftClose={true}
      >
        <p>
          Once you continue to your fingerprint, you will not be able to make
          further changes to your value-drivers.
        </p>
      </FingerprintForm>
    </FingerPrintView>
  );
}

// Values

export function ValueDriversTest() {
  const dispatch = useDispatch();
  const [autoPlaying, setAutoPlaying] = useState(false);
  const routesSelector = useSelector((state: RootState) => {
    return state.dynRoutes;
  });
  useEffect(() => {
    let backRoute: string = routesSelector[routesSelector.length - 1]
      ? routesSelector[routesSelector.length - 1]
      : "mapping-question-2";
    dispatch(
      allActions.appActions.updateFormLocation(
        backRoute,
        "test-your-value-drivers",
        "personal-choice"
      )
    );
  }, []);

  return (
    <FingerPrintView background="navy">
      <FingerprintForm
        name="Test your value drivers"
        message="Thank you. Now we will put your value-drivers to the test. Please answer these questions thoughtfully."
        next="personal-choice"
        back={
          routesSelector[routesSelector.length - 1]
            ? routesSelector[routesSelector.length - 1]
            : "mapping-question-2"
        }
      />
    </FingerPrintView>
  );
}

export function PersonalChoice() {
  const { formData, addToForm } = useFormData();
  const [enoughText, setEnoughText] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      allActions.appActions.updateFormLocation(
        "test-your-value-drivers",
        "personal-choice",
        "personal-choice-values"
      )
    );
  }, []);

  return (
    <FingerPrintView background="darkblue">
      <FingerprintForm
        name="Test your value drivers"
        message="Explain a time you had to make an extremely difficult choice in your personal life."
        next="personal-choice-values"
        back="test-your-value-drivers"
        enoughText={enoughText}
      >
        <FingerprintTextArea
          name="personal choice"
          editForm={addToForm}
          setEnoughText={setEnoughText}
          formData={formData}
        />
      </FingerprintForm>
    </FingerPrintView>
  );
}

export function PersonalChoiceValues(props: any) {
  const { selector, formData, addToForm, getPotentialValues } = useFormData();
  const {
    selectorValues,
    ValidateValueScore,
    ValuesOnFirstRender,
    CalculateValueScores,
  } = useValueData();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      allActions.appActions.updateFormLocation(
        "personal-choice",
        "personal-choice-values",
        "professional-choice"
      )
    );
  }, []);

  useEffect(() => {
    if (Object.keys(selectorValues).length === 0) {
      CalculateValueScores(getPotentialValues());
    }
  }, [selector]);
  return (
    <FingerPrintView background="darkblue">
      <FingerprintForm
        name="Test your value drivers"
        message="Reflecting on what you wrote, which of your values were most involved?"
        next="professional-choice"
        back="personal-choice"
      >
        <ValidationValuesCheckList
          values={Object.keys(selectorValues)}
          formData={formData}
          editForm={addToForm}
          ValidateValueScore={ValidateValueScore}
          name={"personal-choice-values"}
          ValuesOnFirstRender={ValuesOnFirstRender}
        />
      </FingerprintForm>
    </FingerPrintView>
  );
}

export function ProfessionalChoice() {
  const { formData, addToForm } = useFormData();
  const [enoughText, setEnoughText] = useState<boolean>(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      allActions.appActions.updateFormLocation(
        "personal-choice-values",
        "professional-choice",
        "professional-choice-values"
      )
    );
  }, []);

  return (
    <FingerPrintView background="darkblue">
      <FingerprintForm
        name="Test your value drivers"
        message="Now, explain a time you had to make an extremely difficult choice in your professional life."
        next="professional-choice-values"
        back="personal-choice-values"
        enoughText={enoughText}
      >
        <FingerprintTextArea
          name="professional choice"
          editForm={addToForm}
          setEnoughText={setEnoughText}
          formData={formData}
        />
      </FingerprintForm>
    </FingerPrintView>
  );
}

export function ProfessionalChoiceValues(props: any) {
  const { selector, formData, addToForm, getPotentialValues } = useFormData();
  const {
    selectorValues,
    ValidateValueScore,
    ValuesOnFirstRender,
    CalculateValueScores,
  } = useValueData();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      allActions.appActions.updateFormLocation(
        "professional-choice",
        "professional-choice-values",
        "opportunity-conflict"
      )
    );
  }, []);

  useEffect(() => {
    if (Object.keys(selectorValues).length === 0) {
      CalculateValueScores(getPotentialValues());
    }
  }, [selector]);

  return (
    <FingerPrintView background="darkblue">
      <FingerprintForm
        name="Test your value drivers"
        message="Reflecting on what you wrote, which of your values were most involved?"
        next="opportunity-conflict"
        back="professional-choice"
      >
        <ValidationValuesCheckList
          values={Object.keys(selectorValues)}
          formData={formData}
          editForm={addToForm}
          ValidateValueScore={ValidateValueScore}
          name={"professional-choice-values"}
          ValuesOnFirstRender={ValuesOnFirstRender}
        />
      </FingerprintForm>
    </FingerPrintView>
  );
}

export function OpportunityConflict() {
  const { formData, addToForm } = useFormData();
  const [enoughText, setEnoughText] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      allActions.appActions.updateFormLocation(
        "professional-choice-values",
        "opportunity-conflict",
        "opportunity-conflict-values"
      )
    );
  }, []);

  return (
    <FingerPrintView background="darkblue">
      <FingerprintForm
        name="Test your value drivers"
        message="Describe a time you turned down an opportunity because it conflicted with your values."
        next="opportunity-conflict-values"
        back="professional-choice-values"
        enoughText={enoughText}
      >
        <FingerprintTextArea
          name="opportunity conflict"
          editForm={addToForm}
          setEnoughText={setEnoughText}
          formData={formData}
        />
      </FingerprintForm>
    </FingerPrintView>
  );
}

export function OpportunityConflictValues() {
  const name = "opportunity-conflict-values";
  const { selector, formData, addToForm, getPotentialValues } = useFormData();
  const {
    selectorValues,
    ValidateValueScore,
    ValuesOnFirstRender,
    CalculateValueScores,
  } = useValueData();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      allActions.appActions.updateFormLocation(
        "opportunity-conflict",
        "opportunity-conflict-values",
        "disappointment"
      )
    );
  }, []);

  useEffect(() => {
    if (Object.keys(selectorValues).length === 0) {
      CalculateValueScores(getPotentialValues());
    }
  }, [selector]);
  return (
    <FingerPrintView background="darkblue">
      <FingerprintForm
        name="Test your value drivers"
        message="Reflecting on what you wrote, which of your values were most involved?"
        next="disappointment"
        back="opportunity-conflict"
      >
        <ValidationValuesCheckList
          values={Object.keys(selectorValues)}
          formData={formData}
          editForm={addToForm}
          ValidateValueScore={ValidateValueScore}
          name={name}
          ValuesOnFirstRender={ValuesOnFirstRender}
        />
      </FingerprintForm>
    </FingerPrintView>
  );
}

export function Disappointment() {
  const { formData, addToForm } = useFormData();
  const [enoughText, setEnoughText] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      allActions.appActions.updateFormLocation(
        "opportunity-conflict-values",
        "disappointment",
        "disappointment-values"
      )
    );
  }, []);

  return (
    <FingerPrintView background="darkblue">
      <FingerprintForm
        name="Test your value drivers"
        message="Describe a time someone close to you disappointed you."
        next="disappointment-values"
        back="opportunity-conflict-values"
        enoughText={enoughText}
      >
        <FingerprintTextArea
          name="disappointment"
          editForm={addToForm}
          setEnoughText={setEnoughText}
          formData={formData}
        />
      </FingerprintForm>
    </FingerPrintView>
  );
}

export function DisappointmentValues() {
  const { selector, formData, addToForm, getPotentialValues } = useFormData();
  const {
    selectorValues,
    ValidateValueScore,
    ValuesOnFirstRender,
    CalculateValueScores,
  } = useValueData();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      allActions.appActions.updateFormLocation(
        "disappointment",
        "disappointment-values",
        "calculating-values"
      )
    );
  }, []);

  useEffect(() => {
    if (Object.keys(selectorValues).length === 0) {
      CalculateValueScores(getPotentialValues());
    }
  }, [selector]);

  useEffect(() => {}, [selectorValues]);

  return (
    <FingerPrintView background="darkblue">
      <FingerprintForm
        name="Test your value drivers"
        message="Reflecting on what you wrote, which of your values were most involved?"
        next="calculating-values"
        back="disappointment"
      >
        <ValidationValuesCheckList
          values={Object.keys(selectorValues)}
          formData={formData}
          editForm={addToForm}
          ValidateValueScore={ValidateValueScore}
          name={"disappointment-values"}
          ValuesOnFirstRender={ValuesOnFirstRender}
        />
      </FingerprintForm>
    </FingerPrintView>
  );
}

export function CalculatingValues() {
  const { convertDataToString, getPotentialValues } = useFormData();
  const { selectorValues, finalValues, CalculateValueScores, GetFromDB } =
    useValueData();
  const { updateHttpError } = useErrorHandler();
  const location = useLocation();
  const dispatch = useDispatch();

  const history = useHistory();
  const [displayNumber, setDisplayNumber] = useState<number>(0);

  useEffect(() => {
    if (finalValues != undefined) {
      if (finalValues.length > 0) {
        dispatch(allActions.valueActions.savePotentialValues(finalValues));
        setTimeout(() => {
          history.push("/digital-fingerprint/potential-values");
        }, 3000);
      }
    }
  }, [finalValues]);

  const fetchPhrases = async () => {
    GetFromDB().then((res) => {
      if (res !== undefined) {
        if ((res as IError).statusCode > 399) {
          updateHttpError({
            messages: (res as IError).messages,
            statusCode: (res as IError).statusCode,
          });
        }
      } else {
        setTimeout(() => {
          history.push("/digital-fingerprint/potential-values");
        }, 2000);
      }
    });
  };

  useEffect(() => {
    if (location.pathname.includes("calculating-values")) {
      dispatch(
        allActions.appActions.updateFormLocation(
          "disappointment-values",
          "calculating-values",
          "value-order"
        )
      );

      CalculateValueScores(getPotentialValues());

      setTimeout(() => {
        history.push("/digital-fingerprint/value-order");
      }, 4000);
    } else if (location.pathname.includes("calculating-potential-values")) {
      fetchPhrases();
    }
  }, []);

  const messages = [
    "We are now calculating potential Values for your review....",
    "We are now preparing your Values for review...",
  ];
  return (
    <FingerPrintView background="darkblue">
      <h3 style={{ paddingLeft: "10px" }}>{messages[displayNumber]}</h3>
    </FingerPrintView>
  );
}
